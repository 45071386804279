(function($,undefiend){
	function init(){
		var dtap = null;

		// $( '#drilldown' ).dlmenu({
		// $( '#drilldown' ).dlmenu({
		// 	//animationClasses : { classin : 'dl-animate-in-1', classout : 'dl-animate-out-1' }
		// });
		//
		// $( '.ddproducts' ).dlmenu({
		// 	//animationClasses : { classin : 'dl-animate-in-1', classout : 'dl-animate-out-1' }
		// });
		var utilsContent = $('<div>').append($('#mobileheader').clone()).html();


		$("#MMenu").mmenu({
			// options
			// navbar 		: false,

			navbars		: {
				height 	: 4,
				content : utilsContent
			}
		}, {
			// configuration
			offCanvas: {
				pageSelector: "#site-wrapper"
			},
			classNames:{
				selected: 'active'
			}

		});

		var MMenu = $("#MMenu").data( "mmenu" );
		$('#closeMMenu').click(function(){
			MMenu.close();
		});



		var $subMenu = $('#subMenu');
		console.log(  $subMenu );
		 if( $subMenu && $subMenu.length) {
				// lancement submenu
			 $('#subMenu').mmenu({
				 autoHeight: true,
				 offCanvas: false,

			 },{
				 classNames:{
					 selected: 'active'
				 }
			 });

			 // init H sumenu
			 var curSs = $('#subMenu .mm-current');
			 var subh = curSs.find('> ul').outerHeight() + 80;
			 $('#subMenu').height( subh );

			 //change H submenu
			 var subMenu = $("#subMenu").data( "mmenu" );

			 subMenu.bind( "openPanel", function( $panel ) {
				 var subh = $panel.find('> ul').outerHeight() + 80;
				 $('#subMenu').height( subh );
			 });
		 }






	}

	function addLoadListener(func)
	{
		if (window.addEventListener) {
			window.addEventListener("load", func, false);
		} else if (document.addEventListener) {
			document.addEventListener("load", func, false);
		} else if (window.attachEvent) {
			window.attachEvent("onload", func);
		}

	}

	if (document.getElementById && document.createTextNode) {
		addLoadListener(init);
	}
})(jQuery,undefined);


