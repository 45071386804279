//javascript

if (!window.console) {
	var names = ['log', 'debug', 'info', 'warn', 'error', 'assert', 'dir', 'dirxml', 'group', 'groupEnd', 'time', 'timeEnd', 'count', 'trace', 'profile', 'profileEnd'];

	window.console = {};
	var i = 0, n = names.length;
	for (; i < n; i++) {
		window.console[names[i]] = function () {
		};
	}
}

var Site = (function () {

	function init() {
		_initNav();
		_initOthers();
		_initPolyfills();
		_initShare();
		_initHome();
		/*** warning ***/
		// _cookiesWarning();
		// _initMap();
		//_browserWarning();
	}


	function sticky_relocate() {
		var window_top = $(window).scrollTop();
		// var div_top = $('header.main').offset().top;
		if (window_top == 0) {
			$('body').addClass('top');
		}
		else {
			$('body').removeClass('top');
		}
	}

	function _initNav() {
		$(window).scroll(sticky_relocate);
		sticky_relocate();
	}

	function _initOthers() {

		/** svg injector */
		// Elements to inject
		var mySVGsToInject = document.querySelectorAll('img.inject-svg');
		// Do the injection
		SVGInjector(mySVGsToInject, null, function () {
			// mySVGsToInject.forEach(function(el){
			// 	el.clas
			// });
		});

		$(".videopopin").fancybox({maxWidth: "90%", maxHeight: "80%", type: "iframe"});
		//webshims.polyfill();

		$(".fancybox,[rel=lightbox],[rel=gallery]").fancybox({
			maxWidth: '90%',
			maxHeight: '80%',
			autoSize: false,
			closeClick: true
		});


		$('a.printaction').on('click', function () {
			window.print();
		});


		$('[data-wz-toggle]').on('click', function (e) {
			e.preventDefault();
			var target = $(this).data('wz-target');
			console.log(this, 'BTN TGL');
			console.log($(target), '$(target)');
			$(target).toggle();
		});

		$('select.filter_formation').change(function () {
			document.location.href = $(this).val();
		});

		// transform product tabs to occordion on mobile
		$('#productTabs').tabCollapse();

		/*

		 //$('header.main .banner').cycle();

		 $('[data-toggle=tooltip]').tooltip();
		 */
	}

	function _initPolyfills() {

		// Modernizr.load({
		// 	test: Modernizr.input.placeholder,
		// 	nope: [
		// 			'//cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js'
		// 		  ]
		// });

		// Modernizr.load({
		// 	test: Modernizr.mq('only all'),
		// 	nope : [
		// 		'//cdnjs.cloudflare.com/ajax/libs/respond.js/1.4.2/respond.min.js'
		// 	]
		// });
	}

	function _initHome() {
		if ($('html.home').length == 0) return;

		$('#homePromos .list')
			.append('<div id="hpromoPager" class="cycle-pager"></div>')
			.append('<div id="hpromoNext" class="cycle-next"></div>')
			.append('<div id="hpromoPrec" class="cycle-prev"></div>')
			.cycle({
				speed: 600,
				'speed-manual': 200,
				slides: '> article',
				'next': '#hpromoNext',
				'prev': '#hpromoPrev',
				'page': '#hpromoPager',
				'pager-template': '<span></span>',
			});


	}


	function _initShare() {

		var shareFacebook = function (shareLink, shareTitle, shareDescription, shareMedia) {
			window.open('//www.facebook.com/share.php?m2w&s=100&p[url]=' + encodeURIComponent(shareLink) + '&p[images][0]=' + encodeURIComponent(shareMedia) + '&p[title]=' + encodeURIComponent(shareTitle) + '&p[summary]=' + encodeURIComponent(shareDescription), 'Facebook', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
		};
		var shareTwitter = function (shareLink, shareTitle, shareDescription, shareMedia) {
			window.open('https://twitter.com/intent/tweet?original_referer=' + encodeURIComponent(shareLink) + '&text=' + encodeURIComponent(shareTitle) + '%20' + encodeURIComponent(shareLink), 'Twitter', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
		};
		var sharePinterest = function (shareLink, shareTitle, shareDescription, shareMedia) {
			window.open('//pinterest.com/pin/create/button/?url=' + encodeURIComponent(shareLink) + '&media=' + encodeURIComponent(shareMedia) + '&description=' + encodeURIComponent(shareTitle), 'Pinterest', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
		};
		var shareGooglePlus = function (shareLink, shareTitle, shareDescription, shareMedia) {
			window.open('https://plus.google.com/share?url=' + encodeURIComponent(shareLink), 'GooglePlus', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
		};
		var shareLinkedIn = function (shareLink, shareTitle, shareDescription, shareMedia) {
			window.open('//www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(shareLink) + '&title=' + encodeURIComponent(shareTitle) + '&source=' + encodeURIComponent(shareLink), 'LinkedIn', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
		};
		// console.log( 'Init share' );

		$('.socialShare').on('click', 'a', function (e) {
			e.preventDefault();
			var $link = $(this);
			var url = $('meta[name="og:url"]').attr('content');
			var title = $('html head title').text();
			var media = 'http://' + document.location.host + '/icons/apple-touch-icon-152x152.png';
			if ($('meta[name="og:image"]').length > 0) {
				media = $('meta[name="og:image"]').attr('content');
			}
			var descr = $('meta[name=description]').attr('content');

			if ($link.hasClass('facebook')) shareFacebook(url, title, descr, media);
			if ($link.hasClass('twitter')) shareTwitter(url, title, descr, media);
			if ($link.hasClass('pinterest')) sharePinterest(url, title, descr, media);
			if ($link.hasClass('google-plus')) shareGooglePlus(url, title, descr, media);
			if ($link.hasClass('linkedin')) shareLinkedIn(url, title, descr, media);
		});
	}


	function addLoadListener(func) {
		if (window.addEventListener) {
			window.addEventListener("load", func, false);
		} else if (document.addEventListener) {
			document.addEventListener("load", func, false);
		} else if (window.attachEvent) {
			window.attachEvent("onload", func);
		}

	}

	if (document.getElementById && document.createTextNode) {
		addLoadListener(init);
	}
})();

var EcobatiBrandsModule = (function EcobatiBrands($, undefined) {
	var module = this;
	module.imageurl = '';
	module.container = null;
	module.image = null;
	module.offset = 0;
	module.nbC = 1;
	module.bgW = 160;
	module.init = function () {
		module.container = $('#EcobatiBrands');
		var dataimage = module.container.data('image');
		if (dataimage) {
			module.imageurl = dataimage;
		}
		if (!module.imageurl) {
			return;
		}

		module.image = new Image();
		$(module.image).on('load', function () {
			module.start(this);
		})
		module.image.src = module.imageurl;


	}

	module.start = function (img) {
		var el;
		var c = module.container;
		c.html('');
		var iH = img.height;
		var iW = img.width;

		var cW = c.width();
		var nbC = Math.floor(cW / module.bgW);
		if (nbC > 2) {
			nbC--;
		}
		module.nbC = nbC;

		var loCont = [];
		for ($i = 1; $i <= nbC; $i++) {

			el = $('<div id="EB_' + $i + '"></div>');
			el.css('background-image', 'url(' + img.src + ')');
			el.css('background-position', '' + ( $i * module.bgW ) + 'px 0px');

			loCont.push(el);
			c.append(el);
		}
		module.container.css('height', iH);
		module.interval = setInterval(function () {
			for (idx in loCont) {
				loCont[idx]
					.animate({'background-position-y': '-=80px'}, 250)
					.animate({'background-position-x': '+=' + (module.nbC * module.bgW ) + 'px'}, 0)
					.animate({'background-position-y': '+=80px'}, 250)
				;
			}
		}, 3000);
	};

	module.addLoadListener = function (func) {
		if (window.addEventListener) {
			window.addEventListener("load", func, false);
		} else if (document.addEventListener) {
			document.addEventListener("load", func, false);
		} else if (window.attachEvent) {
			window.attachEvent("onload", func);
		}
	}

	if (document.getElementById && document.createTextNode) {
		module.addLoadListener(module.init);
	}

	return module; //return the function itself to reference
})(jQuery, undefined);

var EcobatiModule = (function Ecobati(jQuery, undefined) {
	var module = this;
	var shopsList = null;
	module.init = function (a) {
		console.log('init');
		var shopsmaps = document.querySelectorAll('[data-module="EcobatiShopsMap"]');

		if (shopsmaps) {
			module.loadShops(function () {
				module.initShopsMap();
				// module.initShopsList();
			});
		}

	};

	module.loadShops = function (cb) {
		var locale = document.querySelector('html').lang;
		return $.get('/api/ecobati-shops', {'userlocale': locale, 'setUrl': true}).done(function (response) {
			module.shopsList = response.data;
			console.log(module.shopsList, 'dataload');
			cb();
		});
	};

	module.initShopsList = function () {
		var shopslistcontainer = document.getElementById('shops__list__container');
		var ShopsMapsData = module.shopsList;
		if (!shopslistcontainer) return;
		var countries = [];
		var html = '<ul class="list">';
		[].forEach.call(
			ShopsMapsData,
			function (el) {
				html += '<li>' + module.getShopInfo(el, false) + '</li>';

			});
		html += '<ul>';
		shopslistcontainer.innerHTML = html;
	};

	module.getShopInfo = function (shop, full) {
		var html = '';
		html += '<h3 id="shop_' + shop.id + '">';
		html += '<a href="' + shop.url + '">';
		html += shop.name + '<br><small>' + shop.locality + '</small>';
		html += '</a></h3>';
		if (true === full) {
			html += '<p>' + shop.address + ' <br>' + (shop.address2 != null ? shop.address2 + '<br>' : '') + '' + shop.zipcode + ' ' + shop.locality + ' ' + shop.country + '</p>';
		}

		return html;
	}

	module.initShopsMap = function () {
		var shopsmap = document.getElementById('shops__map__container');
		var ShopsMapsData = module.shopsList;

		if (!shopsmap || !ShopsMapsData) return;
		var mapStyles = [{
			"featureType": "landscape",
			"stylers": [{"hue": "#FFBB00"}, {"saturation": 43.400000000000006}, {"lightness": 37.599999999999994}, {"gamma": 1}]
		}, {
			"featureType": "road.highway",
			"stylers": [{"hue": "#FFC200"}, {"saturation": -61.8}, {"lightness": 45.599999999999994}, {"gamma": 1}]
		}, {
			"featureType": "road.arterial",
			"stylers": [{"hue": "#FF0300"}, {"saturation": -100}, {"lightness": 51.19999999999999}, {"gamma": 1}]
		}, {
			"featureType": "road.local",
			"stylers": [{"hue": "#FF0300"}, {"saturation": -100}, {"lightness": 52}, {"gamma": 1}]
		}, {
			"featureType": "water",
			"stylers": [{"hue": "#0078FF"}, {"saturation": -13.200000000000003}, {"lightness": 2.4000000000000057}, {"gamma": 1}]
		}, {
			"featureType": "poi",
			"stylers": [{"hue": "#00FF6A"}, {"saturation": -1.0989010989011234}, {"lightness": 11.200000000000017}, {"gamma": 1}]
		}];
		var mapOptions = {
			center: {lat: -34.397, lng: 150.644},
			zoom: 16,
			styles: mapStyles
		};
		var map = new google.maps.Map(shopsmap, mapOptions);

		var bounds = new google.maps.LatLngBounds();

		[].forEach.call(
			ShopsMapsData,
			//document.querySelectorAll('footer.infos address'),
			function (el) {
				if (el.gps) {
					var ll = el.gps.split(',');
					var Latlng = new google.maps.LatLng(ll[0], ll[1]);
					map.setCenter(Latlng);
					// titre
					// addresse
					var adr = el.address + ' ' + (el.address2 || '') + ' ' + el.zipcode + ' ' + el.locality + ' ' + el.country;
					// directions
					var dir_url = 'https://www.google.com/maps/dir//' + adr.trim();

					var infowindow = new google.maps.InfoWindow({
						content: module.getShopInfo(el, true) + '<p><a href="' + dir_url + '" target="_blank">Direction</a></p>'
					});
					var marker = new google.maps.Marker({
						position: Latlng,
						map: map,
						icon: '/resources/themes/ecobati/images/logo-mini.png',
						title: el.name,
					});
					bounds.extend(Latlng);

					google.maps.event.addListener(marker, 'click', function () {
						infowindow.open(map, marker);
					});

					// var adr = el.querySelector('.adr').textContent.trim();

				}
			}
		);
		map.fitBounds(bounds);
		map.panToBounds(bounds);

		// $('#daterdv').fdatepicker({startDate:$('#daterdv').attr('min')});

	};

	module.addLoadListener = function (func) {
		if (window.addEventListener) {
			window.addEventListener("load", func, false);
		} else if (document.addEventListener) {
			document.addEventListener("load", func, false);
		} else if (window.attachEvent) {
			window.attachEvent("onload", func);
		}

	}

	if (document.getElementById && document.createTextNode) {
		module.addLoadListener(module.init);
	}

	return module; //return the function itself to reference

}(jQuery));

/// Browser upgrade
(function () {
	var $buoop = {
		c: 2,
		reminder: 240,
		l: $('html').attr('lang') || 'fr',
		test: false

	};

	function $buo_f() {
		var e = document.createElement("script");
		e.src = "//browser-update.org/update.min.js";
		document.body.appendChild(e);
	};
	try {
		document.addEventListener("DOMContentLoaded", $buo_f, false)
	}
	catch (e) {
		window.attachEvent("onload", $buo_f)
	}

})();


/// Cookies message
(function () {
	var debug = false;

	function $cookies_f() {
		if ($.cookie('warning_cookie') === undefined || debug) {
			if (!!MESSAGE_WARNING_COOKIES && MESSAGE_WARNING_COOKIES.length > 10) {
				$('<div id="warning_cookie" class="warning_cookie alert alert-warning alert-dismissible fade in" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + MESSAGE_WARNING_COOKIES + '<button type="button" class="btn btn-sm btn-default" data-dismiss="alert" aria-label="Close">OK</button></div>')
					.on('closed.bs.alert', function () {
						$.cookie('warning_cookie', 1, {expires: 100, path: '/'});
						// do something…
					})
					.appendTo('body');
			}
		}
	}

	try {
		document.addEventListener("DOMContentLoaded", $cookies_f, false)
	}
	catch (e) {
		window.attachEvent("onload", $cookies_f)
	}

})();