(function($){

	function init()
	{
		console.log($('#newsletterSubscribe'),'NL');
		$('#newsletterSubscribe').on('submit',subscribe);
	}

	function subscribe(e)
	{
		e.preventDefault();
		// console.log(e);
		var $form = $(this);
		var $email = $form.find('#emailsub');
		var $btn = $form.find('#submitNLS');

		$btn.attr('disabled','disabled');
		$btn.find('i').show();
		$btn.find('span').hide();

		// console.log( $email.val(),'MAIL');

		$.ajax({
	  		method : 'POST',
	  		url : $form.attr('action'),
	  		data : {
	  			email : $email.val(),
	  			format : 'json'
	  		},
	  		dataType : 'json',
	  		success : function(response){
		  		// console.log(response);

		  		if( !response.success){
		  			$.notify( { message :  response.message  , type : 'danger'} );
		  		}else{
		  			$.notify( { message : response.message , type : 'success'} );
		  		}
		  		$email.val('');
		  		$btn.attr('disabled',false);
				$btn.find('i').hide();
				$btn.find('span').show();
		  	},
		  	error : function( response ){
		  		$.notify( { message :  "Subscription error"  , type : 'danger'} );
		  		$btn.attr('disabled',false);
				$btn.find('i').hide();
				$btn.find('span').show();
		  	}


	  	})	;

	}

	function addLoadListener(func) {

		if (window.addEventListener) {
			window.addEventListener("load", func, false);
		} else if (document.addEventListener) {
			document.addEventListener("load", func, false);
		} else if (window.attachEvent) {
			window.attachEvent("onload", func);
		}
	}

	if (document.getElementById && document.createTextNode) {
		addLoadListener(init);
	}
})(jQuery);